import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import Footer from '../components/footer'
import Countdown from "react-countdown";


import { Link } from "gatsby";

import UserTopbar from "../components/userTopbar";

import tournamentsGridd from '../images/tournaments-grid.svg'

import mt1 from '../images/t1.png'
import mt2 from '../images/t2.png'
import mt3 from '../images/t3.png'
import mt4 from '../images/t4.png'
import mt5 from '../images/t5.png'

import u1 from '../images/user_1.png'
import u2 from '../images/user_2.png'
import u3 from '../images/user_3.png'
import u7 from '../images/u7.png'

import filter from '../images/filter_icon.png'


import '../scss/tournaments.scss'
import 'semantic-ui-css/semantic.min.css'
import Selectable from "../components/selectable/index.jsx";
import SwitchButton from "../components/switchButton/index.jsx";

export default class tournaments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      following: false,
      player: false,
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
  }

  renderer({ days, hours, minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      return <p>paso</p>
    } else {
      // Render a countdown
      return <div className="countdown">
        <div className="countdown--time inv">
        </div>
        <div className="countdown--time">
          <span className="number">
            {days}
          </span>
          <span className="text">
          días
          </span>
        </div>
        <div className="countdown--time">
          <span className="number">
            {hours}
          </span>
          <span className="text">
            horas
          </span>
        </div>
        <div className="countdown--time">
          <span className="number">
            {minutes}
          </span>
          <span className="text">
            minutos
          </span>
        </div>
        <div className="countdown--time">
          <span className="number">
            {seconds}
          </span>
          <span className="text">
            segundos
          </span>
        </div>
        <div className="countdown--time inv">
        </div>
      </div>;
    }
  };

  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="tournaments" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar onClick={() => this.setState({ player: true })} onClose={() => this.setState({ player: false })} player={this.state.player} user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">


                      <section className="trooper--section">
                        <div className="linear--header">
                          <h3>
                          Torneos
                          </h3>

                        </div>
                        <p className="header--p">
                        ¿Buscás competir y ganar recompensas? Esta es la sección para vos.
                        </p>
                      </section>

                      <section className="logos--section">
                        <div className="wrapper wow fadeIn" data-wow-offset="200">
                          <div className="logo--title">
                            <h2 className="title--present">Sumate a la <span>Troop CUP 21</span></h2>
                            <h2 className="text--present">Conectá y conocé a otros jugadores, creá equipos competitivos, conocé nuevos
                              compañeros y competí para convertirte en el gamer que siempre soñaste.</h2>
                          </div>
                          <Countdown
                            daysInHours={false}
                            date={new Date("December 11, 2021 14:00:00")}
                            renderer={this.renderer}
                          />
                          <Link to="/troop-cup" className="button--global yellow">Más Información</Link>
                          {/* <LogoSlider/> */}
                        </div>
                      </section>

                      <section className="trooper--section">
                          <img className="img-grid" src={tournamentsGridd} />
                      </section>

                    </div>
                  </>
                )}
              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}
